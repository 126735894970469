<template>
  <v-app-bar id="app-bar" absolute app color="transparent" flat height="75" style="z-index: 100">
    <div class="drawer-toggle-wrapper mr-3" :class="drawer ? 'hide' : 'show'">
      <v-btn aria-label="Side Menu Toggle" class="drawer-toggle" @click="setDrawer(!drawer)">
        <v-icon role="presentation" class="drawer-toggle-icon">
          {{ $i('common.menu') }}
        </v-icon>
      </v-btn>
    </div>
    <stemble-breadcrumbs :max-items="4" />
    <v-spacer />
    <div class="mx-0" />
    <v-tooltip v-if="supportLink" bottom>
      <template v-slot:activator="{attr, on}">
        <v-btn
          :aria-label="$t('appBar.iconAriaLabels.support')"
          class="ml-2"
          min-width="0"
          text
          v-bind="attr"
          :href="supportLink"
          target="_blank"
          data-test="support-link"
          v-on="on"
        >
          <v-icon>{{ $i('common.help') }}</v-icon>
        </v-btn>
      </template>
      {{ $t('support') }}
    </v-tooltip>
    <v-menu bottom left min-width="200" offset-y origin="top right" transition="scale-transition">
      <template v-slot:activator="{attrs, on}">
        <v-btn
          :aria-label="$t('appBar.iconAriaLabels.settings')"
          class="ml-2"
          min-width="0"
          text
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>{{ $i('common.settings') }}</v-icon>
        </v-btn>
      </template>

      <v-list :tile="false" flat nav light>
        <v-card-text class="ml-4">
          <v-row align="center" no-gutters>
            <v-col cols="auto">
              <span style="font-size: 13px">
                {{ $t('dark-mode') }}
              </span>
            </v-col>

            <v-spacer />

            <v-col cols="auto" class="pr-lg-6 pr-md-6">
              <v-switch
                v-model="$vuetify.theme.dark"
                class="ma-0 pa-0"
                color="secondary"
                hide-details
              />
            </v-col>
          </v-row>
          <v-row class="mt-6" align="center" no-gutters>
            <v-col cols="auto">
              <span style="font-size: 13px">
                {{ $t('sidebar-mini') }}
              </span>
            </v-col>

            <v-spacer />

            <v-col cols="auto" class="pr-lg-6 pr-md-6">
              <v-switch v-model="internalValue" class="ma-0 pa-0" color="secondary" hide-details />
            </v-col>
          </v-row>
          <v-row v-if="$gate.isSuperUser()" class="mt-6" align="center" no-gutters>
            <v-tooltip activator="#errorDumpButton" :disabled="$errorReporting.hasErrors" bottom>
              There are no errors
            </v-tooltip>
          </v-row>
        </v-card-text>
      </v-list>
    </v-menu>

    <div class="mx-0" />

    <v-btn
      :aria-label="$t('appBar.iconAriaLabels.notifications')"
      class="ml-2"
      min-width="0"
      text
      href="/notifications"
    >
      <v-icon v-if="!notificationsCount">mdi-bell</v-icon>
      <div v-if="notificationsCount" style="position: relative">
        <v-icon>mdi-bell</v-icon>
        <span class="notification-count-wrapper">
          <span class="notification-count">
            {{ notificationsCount }}
          </span>
        </span>
      </div>
    </v-btn>
  </v-app-bar>
</template>

<script>
import {VHover, VListItem} from 'vuetify/lib';
import StembleBreadcrumbs from '@/appbar/StembleBreadcrumbs';
import {mapMutations, mapState} from 'vuex';
import Proxyable from 'vuetify/lib/mixins/proxyable';
import {MESSAGES} from '@/router/route-names';
import {getNotificationsCount} from '@/notifications/api/notifications';
import {pennant} from '@/feature-flags/pennant';

export default {
  name: 'AppBar',

  components: {
    StembleBreadcrumbs,
    AppBarItem: {
      render(h) {
        return h(VHover, {
          scopedSlots: {
            default: ({hover}) => {
              return h(
                VListItem,
                {
                  attrs: this.$attrs,
                  class: {
                    'black--text': !hover,
                    'white--text secondary elevation-12': hover,
                  },
                  props: {
                    activeClass: '',
                    dark: hover,
                    link: true,
                    ...this.$attrs,
                  },
                },
                this.$slots.default
              );
            },
          },
        });
      },
    },
  },

  mixins: [Proxyable],

  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      color: 'primary',
      notificationsCount: 0,
      messages: {
        title: this.$t('messages'),
        to: {name: MESSAGES},
      },
      dialogs: {
        logout: false,
      },
    };
  },

  computed: {
    ...mapState(['drawer']),
    supportLink() {
      return '/support';
    },
  },

  watch: {
    color(val) {
      this.$vuetify.theme.themes[this.isDark ? 'dark' : 'light'].primary = val;
    },
    scrim(val) {
      this.$store.commit('SET_SCRIM', val);
    },
  },

  mounted() {
    getNotificationsCount().then((response) => {
      this.notificationsCount = response.data.count;
    });
  },

  methods: {
    ...mapMutations({
      setDrawer: 'SET_DRAWER',
    }),

    logout() {
      this.$auth.logout();
    },
  },
};
</script>

<style scoped>
.drawer-toggle-wrapper {
  display: grid;
  grid-template-rows: 1fr;
  transition: all ease-out 150ms;
}

.drawer-toggle {
  transition: all ease-out 150ms;
  background: transparent !important;
  border: none;
  box-shadow: none;
  padding: 12px 10px;
  margin: 0 0 0 0 !important;
  min-width: 0 !important;
}

.drawer-toggle-icon {
  font-size: 25px;
  color: rgb(38, 38, 38) !important;
}

.drawer-toggle-wrapper.show {
  grid-template-columns: 1fr;
}

.drawer-toggle-wrapper.show .drawer-toggle {
  opacity: 1;
}

.drawer-toggle-wrapper.hide {
  grid-template-columns: 0;
}

.drawer-toggle-wrapper.hide .drawer-toggle {
  opacity: 0;
  transform: translate3d(-200%, 0, 0);
}

.notification-count-wrapper {
  background-color: rgb(255, 151, 0);
  box-shadow:
    inset 0px 0px 5px -1px rgb(255, 51, 1),
    0px 0px 4px -1px rgb(254, 101, 0);
  padding: 1px 4.5px;
  position: absolute;
  right: -0.75rem;
  top: -0.5rem;
  font-size: 0.875rem;
  border-radius: 100px;
}

.notification-count {
  filter: drop-shadow(rgba(0, 0, 0, 0.05) 0px 1px 1px);
  color: #fff;
  font-weight: 700;
  letter-spacing: 0.18px;
  line-height: 1;
}

.logout-wrapper-button {
  width: 100%;
  padding: 0;
}
</style>
